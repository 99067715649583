import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { CentrationJob, CentrationJobStatus } from '../models/centration-job.model';
import { AdminAppEnvironment as environment } from 'visenvironment';
import { CentrationResult } from '../models/centration-result.model';
import { CentrationDetails } from '../models/centration-details.model';

@Injectable({ providedIn: 'root' })
export class CentrationService {
    constructor(
        private readonly http: HttpClient,
        private readonly oAuth: OAuthService
    ) {
    }

    public getCentrationJobs() {
        return this.http.get<CentrationJob[]>(
            `${environment.connectivity.centration}`,
            {
                headers: {
                    Authorization: `Bearer ${this.oAuth.getIdToken()}`
                }
            }
        );
    }

    public getCentrationResult(centrationJobId: string) {
        return this.http.get<CentrationResult>(
            `${environment.connectivity.centration}/${centrationJobId}/result`,
            {
                headers: {
                    Authorization: `Bearer ${this.oAuth.getIdToken()}`
                }
            }
        );
    }

    public getCentrationDetails(centrationJobId: string) {
        return this.http.get<CentrationDetails>(
            `${environment.connectivity.centration}/${centrationJobId}/result`,
            {
                headers: {
                    Authorization: `Bearer ${this.oAuth.getIdToken()}`
                }
            }
        );
    }

    public updateCentrationJob(centrationJobId: string, status: CentrationJobStatus) {
        const requestBody = {
            id: centrationJobId,
            status
        };
        return this.http.put(
            `${environment.connectivity.centration}/${centrationJobId}`,
            JSON.stringify(requestBody),
            {
                headers: {
                    Authorization: `Bearer ${this.oAuth.getIdToken()}`
                }
            }
        );
    }
}
