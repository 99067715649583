<div class="banner-input-container">
    <div class="banner-container">
        <div *ngIf="loading" class="col s12 table-overlay">
            <mat-spinner [diameter]="50" mode="indeterminate"></mat-spinner>
        </div>
        <ng-container *ngIf="(customBanner$ | async); then image; else text"></ng-container>
        <ng-template #image style="min-height: 66px;">
            <img [src]="(customBanner$ | async)" class="" />
            <button [disabled]="((customBanner$ | async) == null) || !canEdit" mat-icon-button color="primary" vcldAutomationId="adm_app_vcld-banner-input_delete-banner_btn"
                (click)="removeBanner($event)">
                <mat-icon>delete</mat-icon>
            </button>
        </ng-template>
        <ng-template #text>
            
        </ng-template>
       
        
    </div>

    <div class="btns-container">

        <div class="input-container">
            <p class="quick-silver-text"> 
                {{ 'pages.store-settings.banner.upload' | translate }}
                <mat-icon style="font-size: 14px;" matTooltipClass="banner-tooltip" (click)="onClickEvent($event)"  matTooltip="{{ 'tooltips.banner.ratio' | translate: {aspectRatio: '16:9'} }}
                 {{ 'tooltips.banner.filesize' | translate: {fileSize: '4MB'} }} 
                 {{ 'tooltips.banner.fileformat' | translate: {fileFormat: '.jpg, .jpeg, .png'} }}" >
                    info
                </mat-icon>
            </p>
            <div>
                <custom-file-upload  [origin]="'banner-Input'" (inputFileChange)="fileInputChanged($event)" accept=".png, .jpg, .jpeg" [disabled]="!canEdit" [fileUpdated]="bannerUploaded"></custom-file-upload>
            </div>
            
        </div>
        <button [disabled]="isDisabled || !canEdit || !(aspectRatioRestriction$ | async)" mat-mini-fab color="primary" vcldAutomationId="adm_app_vcld-banner-input_upload_btn"
            (click)="saveBanner($event)">
            <mat-icon>upload</mat-icon>
        </button>
    </div>
</div>