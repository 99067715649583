import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InvitationMailTemplate } from '../../models/invitation-mail-template.model';
import { PreferencesService } from '../../services/preferences.service';

@Component({
  selector: 'app-invitation-mail-preview-dialog',
  templateUrl: './invitation-mail-preview-dialog.component.html',
  styleUrls: ['./invitation-mail-preview-dialog.component.scss']
})
export class InvitationMailPreviewDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) private data: InvitationMailTemplate, private pref: PreferencesService) {
  }
  public isLoading: boolean = true;

  public async ngOnInit(): Promise<void> {
    if (this.data) {
      const htmlText = await this.pref.sendInvitationEmailForPreview(this.data);
      const iframe = document.getElementById('iframe_html') as HTMLIFrameElement;
      if (iframe) {
        let doc = iframe.contentDocument ? iframe.contentDocument : iframe.contentWindow.document;
        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write("");
        iframe.contentWindow.document.close();
        if (doc) doc.writeln(htmlText);
        iframe.style.display = 'block';
      }
      this.isLoading = false;
    }
  }
}
