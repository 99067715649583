<div class="table-overlay" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>

<div class="row">
    <div class="col s10 offset-s1 small-page-fix">
        <div class="col s12" style="margin-top: 64px">
            <p vcldautomationid="adm_app_vis-adm-role-management-page_header-roles-and-permission_title" class="adm-title">
                {{ "pages.role-management.title" | translate }}
            </p>
        </div>

        <div class="col s12" style="margin-top: 24px"></div>

        <div class="col s12" style="margin-top: 24px; margin-bottom: 24px">
            <p vcldautomationid="adm_app_vis-adm-role-management-page_header-available-roles_title"
                class="col s10"
                style="
                    margin-top: 8px;
                    line-height: 42px;
                    font-size: 16px;
                    font-weight: bold;
                "
            >
                {{ "pages.role-management.roles" | translate }}
            </p>

            <div class="col s2" style="padding: 0px 8px">
                <button vcldautomationid="adm_app_vis-adm-role-management-page_save-role_btn" mat-flat-button [color]="'primary'" [disabled]="security.hasChanges() ? false : true" (click)="onSave()">
                    {{ "components.buttons.save" | translate }}
                </button>
            </div>
        </div>

        <mat-accordion class="col s12" id="availableRoles">
            <mat-expansion-panel
                *ngFor="let role of security.getAvailableRoles(); let i = index"
            >
                <mat-expansion-panel-header vcldautomationid="adm_app_vis-adm-role-management-header-roles-expansion-panel_icon">
                    <mat-panel-title vcldAutomationId="adm_app_vis-adm-role-management-page_available-roles_list">{{ role.id }}</mat-panel-title>
                </mat-expansion-panel-header>

                <table
                    mat-table
                    [dataSource]="security.getPermissions()"
                    style="width: 100%"
                >
                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "pages.role-management.active" | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox [color]="'primary'" vcldAutomationId="adm_app_vis-adm-role-management-page_role-permissions_cbox"
                                [checked]="
                                    security.checkAvailableRolePermission(
                                        role,
                                        row.key
                                    )
                                "
                                (change)="
                                    onChangeAvailableRolePermissionsAssignment(
                                        role,
                                        row
                                    )
                                "
                                (click)="$event.stopPropagation()"
                            >
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="key">
                        <th mat-header-cell *matHeaderCellDef>
                            {{
                                "pages.role-management.permission_key"
                                    | translate
                            }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.key }}</td>
                    </ng-container>

                    <ng-container matColumnDef="displayName">
                        <th mat-header-cell *matHeaderCellDef>
                            {{
                                "pages.role-management.permission_name"
                                    | translate
                            }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ getTranslation(row.key) | translate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>
                            {{
                                "pages.role-management.permission_description"
                                    | translate
                            }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ getTranslation(row.description) | translate }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                    ></tr>
                </table>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
