<div class="row">
    <div class="col s10 offset-s1 container small-page-fix">
        <div class="col s12">
            <p class="adm-title"> {{'pages.adm-overview.title' | translate}} </p>
        </div>

        <div class="col s12 greeting-box">
            <p class="text bold"> {{'pages.adm-overview.welcome' | translate: {name: userName} }} </p>
        </div>

        <div class="col s12 tile-menu">
            <p class="text"> {{'pages.adm-overview.select' | translate }} </p>
            <vcld-adm-nav-tile pk="admin_view_avatarsessionlist" title="{{'pages.adm-overview.avatars' | translate }}" link="/avatar-overview"
                icon="perm_identity">
            </vcld-adm-nav-tile>
            <div featureFlag="DMTEnabled">
                <vcld-adm-nav-tile pk="admin_view_marketing" title="{{'pages.adm-overview.dmt' | translate }}"
                    link="/marketing" icon="mail_outline">
                </vcld-adm-nav-tile>
            </div>
            <vcld-adm-nav-tile pk="admin_view_ecpsettings" title="{{'pages.adm-overview.settings' | translate }}" link="/store-settings"
                icon="settings"></vcld-adm-nav-tile>
        </div>
    </div>
</div>