import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, ErrorHandler } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { AdminAppEnvironment as environment } from 'visenvironment';
import { SnackbarComponent } from '../app-admin/components/snackbar/snackbar.component';
import { ApplicationInsightsService } from '../app-admin/services/applicationInsights.Service';

@Injectable({providedIn: 'root'})
export class AdminErrorHandler implements ErrorHandler {

    private readonly applicationCode: string = 'A';

    private currentUrl: string = '';
    private previousUrl: string = '';

    private readonly debugFlag = localStorage.getItem('vcld_debug') == 'true';

    private occuredErrors: Array<string> = [];

    private heading: string;
    private message: string;

    constructor(
        private snack: MatSnackBar,
        private router: Router,
        private translate: TranslateService,
        private appInsights: ApplicationInsightsService,
    ) {
        this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((navigation: NavigationEnd) => {
            this.previousUrl = (this.currentUrl == '' ? navigation.url : this.currentUrl);
            this.currentUrl = navigation.url;
        });
    }

    handleError(error: Error): void {
        if (environment.production || !this.debugFlag) {
            const pageCode = this.getPageCode();
            let errorCode = '';
            let functionCode = '';

            if (error instanceof HttpErrorResponse) {
                const e = error as HttpErrorResponse;
                errorCode = this.getHttpErrorCode(e);
                functionCode = this.getHttpErrorFunction(e);
            } else {
                errorCode = '9';
                functionCode = this.getApplicationErrorFunction(error);
            }

            const visErrorCode = `${this.applicationCode}${pageCode}${functionCode}${errorCode}`;

            /* 
            Connection Error - 400 
            Generic Error - 500 & 404
            Reload --> Generic Error - 401 & 402 & 9 (unknown)
            */

            /* 
            HTTP 400
            Display connection error 
            */
            if (errorCode == '0' || functionCode == 'z') {
                this.connectionSnack(visErrorCode);
                /* 
                HTTP 500
                HTTP 404
                Display generic error message
                */
            } else if (errorCode == '5' || errorCode == '4') {
                this.supportSnack(error, visErrorCode);
                /* 
                HTTP 401
                HTTP 402
                ErrorCode 9 (unknown)
                Display reload message if error code occured for the first time 
                Display generic error message if error code occures another times
                */
            } else if (errorCode == '1' || errorCode == '2' || errorCode == '9') {
                if (sessionStorage.getItem('vcld_error')) {
                    this.occuredErrors = JSON.parse(sessionStorage.getItem('vcld_error'));
                }
                if (!this.occuredErrors.includes(visErrorCode)) {
                    this.occuredErrors.push(visErrorCode);
                    sessionStorage.setItem('vcld_error', JSON.stringify(this.occuredErrors));
                    this.reloadSnack(visErrorCode);
                } else {
                    this.supportSnack(error, visErrorCode);
                }
            }

            console.error(this.heading + this.message + this.translate.instant('errors.support'), visErrorCode);

        } else {
            this.snack.open(`${error.name}: ${error.message}`, 'FML');
            console.error(error);
        }
    }

    private getHttpErrorCode(error: HttpErrorResponse): string {
        const s = error.status;

        switch (s) {
            case 400:
                return '0';

            case 401:
                return '1';

            case 404:
                return '4';

            case 500:
                return '5';

            default:
                return '2';
        }
    }

    /* 
    Returns the first two letters of the last part from the url
    If the last part starts with a digit, the penultimate part is returned.
    Possible return values are: 
        - Av (AvatarCreationSessions)
        - ca (campaign)
        - ce (centration-jobs)
        - co (contact-mail | component
        - cu (customers)
        - ec (ecp-settings | ecp-onboarding | ecplogo)
        - fi (filter)
        - fr (framecollections)
        - pr (previewtemplate)
        - re (recommendation)
        - se (security | select-customers | sendcampaign | settings | sessionStatus
        - te (template)
        - th (thumbnail)
        - ze (zeiss-logo)

        - IDs not filtered by RegEx (e.g. Hex)
    */
    private getHttpErrorFunction(error: HttpErrorResponse): string {
        const s = error.url.split('/').map(s => s.toLowerCase());
        let errorFunction = s.pop();

        /* Checks if first symbol is a digit */
        if (errorFunction.match(/^\d/)) {
            errorFunction = s.pop();
        }
        return errorFunction.slice(0, 2);
    }

    private getApplicationErrorFunction(error: Error): string {

        if (error.message.toLowerCase().includes('expressionchangedafterithasbeencheckederror')) {
            return 'Exch'
        }

        if (error.message.toLowerCase().startsWith('uncaught (in promise): httperrorresponse:')) {
            return 'z';
        }

        return '#';
    }


    /*
    Returns the code of a page, possible return values are: 
        - h (home)
        - as (avatar-sessions)
        - m (marketing)
            - al (avatar-list)
            - fl (frame-list)
            - rs (recommendation-settings)
            - rl (recommendation-list)
            - ee (edit-email)
            - sm (send-mail)
            - cs (campaign-settings)
            - h (history)
        - ss (store-settings)
        - c (centration)
        - as (app-settings)
        - ow (onboarding-wizard)
    Return value is not unique! 
    For Avatar-Sessions-Page and App-Settings-Page the same code is returned.
     */
    private getPageCode(): string {
        const s = this.currentUrl.split('/');
        let page = s.pop().split('-');

        let pageCodeFirst = page[0].charAt(0);

        if (page.length > 1) {
            let pageCodeSecond = page[1].charAt(0);
            return pageCodeFirst + pageCodeSecond;
        }
        return pageCodeFirst;
    }

    private logAppInsights(error: Error, visErrorCode: string, msg: string): void {
        this.appInsights.logException(error, { visucloudErrorCode: visErrorCode, message: msg });
    }

    private supportSnack(error: Error, visErrorCode: string) {
        this.heading = 'errors.sorry';
        this.message = 'errors.try_again';
        this.logAppInsights(error, visErrorCode, this.message);

        this.openCustomSnackbar(true, visErrorCode);
    }

    private reloadSnack(visErrorCode: string) {
        this.heading = 'errors.sorry';
        this.message = 'errors.reload';

        this.openCustomSnackbar(false, visErrorCode);
    }

    private connectionSnack(visErrorCode: string) {
        this.heading = 'errors.information';
        this.message = 'errors.connection_error';

        this.openCustomSnackbar(false, visErrorCode);
    }

    private openCustomSnackbar(support: boolean, visErrorCode: string) {
        if (visErrorCode != 'AcsExch9' && visErrorCode != 'AeeExch9' && visErrorCode != 'Aalth4' && visErrorCode != 'ArsExch9' && visErrorCode != 'Aflth4') {
            this.snack.openFromComponent(SnackbarComponent, {
                data: {
                    icon: 'info',
                    heading: this.heading,
                    message: this.message,
                    btnIcon: 'close',
                    support: support
                },
                panelClass: 'error-panel',
                verticalPosition: 'top',
                horizontalPosition: 'center'
            });
        }
    }
}