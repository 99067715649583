import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'vcld-adm-material-input',
  templateUrl: './material-input.component.html',
  styleUrls: ['./material-input.component.scss']
})

export class MaterialInputComponent {


  @Input()
  public enabled: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Output()
  public onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onCheckboxChanged(material: MatCheckboxChange): void {
    this.onChange.emit(material.checked);
  }

}