
import { AuthConfig } from 'angular-oauth2-oidc';
import { AdminAppEnvironment as environment } from 'visenvironment';

export const authConfig: AuthConfig = {
    clientId: environment.authentication.clientId,
    redirectUri: `${window.location.href}/after`,
    clearHashAfterLogin: environment.authentication.clearHashAfterLogin,
    disableAtHashCheck: environment.authentication.disableAtHashCheck,
    requestAccessToken: environment.authentication.requestAccessToken,
    scope: environment.authentication.scope,
    showDebugInformation: environment.authentication.showDebugInformation,
    skipIssuerCheck: environment.authentication.skipIssuerCheck,
    strictDiscoveryDocumentValidation: environment.authentication.strictDiscoveryDocumentValidation,
    timeoutFactor: environment.authentication.timeoutFactor,
    postLogoutRedirectUri: `${window.location.origin}/logout`,
    responseType: environment.authentication.responseType
};

export const discoverDocumentOverride = environment.authentication.overrideDiscoveryDocumentUri;

export const discoverDocumentOverrideSignUp = environment.authentication.overrideDiscoveryDocumentUriSignUp;
