export class InvitationMailTemplate {
    /** Internal id of the template */
    public id?: string;

    /** Language of the template */
    public language: string;

    /** Optician to whon template belongs or 'default' */
    public opticianId: string | 'default';

    /** List of components used in template, order determines frontend order */
    public components: InvitationMailComponent[];
}

export class InvitationMailComponent {
    public id: string;

    /** Displayname of the component */
    // public name: string;

    /** Component type */
    // public type: 'TEXT' | 'TEXTAREA';

    /** The value of the component (Set by ECP or default) */
    public value: string;

    /** Determines if the component can be edited by the user */
    public readOnly: boolean;
}

/**
 * This is the Component Template which is stored in CosmosDB "Invitation Component" Container.
 * This Describes how a Mail-Component looks like
 * The value of this will be overwritten by the "component" stored in a template.
 */
export class InvitationMailDatabaseComponent {

    public id: string;

    public language: string;

    public type: 'TEXT' | 'TEXTAREA';

    public DefaultValue: string;

    public name: string;

    public isMandatory: boolean;

    public HTML: string | 'predefined';
}