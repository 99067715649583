/* eslint-disable @angular-eslint/component-selector */
import { Component } from '@angular/core';
import { AdminAppEnvironment as environment } from 'visenvironment';

@Component({
  selector: 'vcloud-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {

  public get zeissLogo() {
    return environment.connectivity.zeiss_logo;
  }
}
