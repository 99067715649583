import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'jobSource' })
export class JobSourcePipe implements PipeTransform {
    transform(value: number): string {
        if(value === 0)
            return 'ECP';
        return value === 1 ? 'JLP' : 'INVALID';
    }
}
