import { Directive, OnInit, Input, ElementRef, HostBinding } from '@angular/core';
import { SecurityService } from '../services/security.service';


@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[pk]',
})
export class PermissionKeyDirective implements OnInit {

    @Input() pk: string;

    @HostBinding('attr.disabled') public disabled;

    constructor(public el: ElementRef, private security: SecurityService) {
    }

    async ngOnInit() {
        // console.log(`[Security Directive] check permission ${this.pk}`);
        const role = await this.security.canSeeAsync(this.pk, false);
        if (role) {
            // console.log(`[Security Directive] canSee? => [OK] item with permission ${this.pk}`);
            this.allowAccessStyle();
        } else {
            // console.log(`[Security Directive] canSee? => [FAILED] item with permission ${this.pk}`);
            this.forbiddenAccessStyle();
        }

    }

    private forbiddenAccessStyle() {
        if (this.isButton()) {
            this.disabled = true;
        } else if(this.isInput()) {
            this.disabled = true;
        } else {
            let el = this.el?.nativeElement;
            if (el) el.setAttribute('style', 'display: none !important');
        }
    }

    private allowAccessStyle() {
        this.el.nativeElement.style.display = 'flex';
    }

    private isButton() {
        return this.el.nativeElement.tagName.toUpperCase() === 'BUTTON';
    }

    private isInput() {
        return this.el.nativeElement.tagName.toUpperCase() === 'INPUT';
    }
}

