export class CentrationJob {

    public id: string;

    public status: CentrationJobStatus;

    public sessionId: string;

    public resultHash: string;

    public reviewer: string;

    public reviewerEmail: string;

    public createdAt: Date;

    public qualityCheckAt: Date;

    public opticianId: string;

    public source: CentrationJobSource;

    public jobType: CentrationJobType;

}

export enum CentrationJobStatus {
    NOT_STARTED,
    PENDING,
    APPROVED,
    REJECTED
}

export enum CentrationJobSource {
    ECP,
    JLP
}

export enum CentrationJobType {
    AUTOMATED,
    INTERACTIVE
}
