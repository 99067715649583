<div class="dialog-header icon-centering">
    <mat-icon class="icon"> warning </mat-icon>
    <p mat-dialog-title class="bold">{{'components.dialogs.language-change.title' | translate}}</p>
</div>
<mat-dialog-content>
    <div class="centering">
        <p>{{'components.dialogs.language-change.subtitle-1' | translate }} </p>
        <p>{{'components.dialogs.language-change.subtitle-2' | translate }} </p>
    </div>
</mat-dialog-content>
<mat-dialog-actions [align]="'center'">
    <button mat-stroked-button color="primary" [mat-dialog-close]="false"> {{'components.buttons.cancel' | translate}}
    </button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true"> {{'components.buttons.ok' | translate}} </button>
</mat-dialog-actions>