import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs/operators';
import { DemoAvatarUploadService } from '../../services/demo-avatar-service';

@Component({
  selector: 'vcld-demo-avatar-upload-dialog',
  templateUrl: './demo-avatar-upload.dialog.component.html',
  styleUrls: ['./demo-avatar-upload.dialog.component.scss']
})

export class DemoAvatarUploadDialogComponent {
    public customerNumber: string = "0000620681";
    public loading: boolean = false;

    constructor(
      private demoService: DemoAvatarUploadService,
      private dialogRef: MatDialogRef<DemoAvatarUploadDialogComponent>,
    ){}

    public async uploadAvatar(consumerId: string, storeId: string, cameronAatar?: boolean) {
        await this.demoService.uploadDemoAvatar(consumerId, storeId, this.customerNumber, cameronAatar).pipe(
            tap(() => this.loading = true)
        ).toPromise();
        this.dialogRef.close();
    }
}