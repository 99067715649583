import { HttpClient } from '@angular/common/http';
import { Injectable} from '@angular/core';
import { Subject } from 'rxjs';
import { AdminAppConfig } from '../types/appConfig';
import { AdminAppEnvironment as environment } from 'visenvironment';

@Injectable({providedIn: 'root'})
export class AppConfigService {

    private config: AdminAppConfig;

    public config$ = new Subject<AdminAppConfig>();

    constructor(private _http: HttpClient) {
    }

    public initializeAppConfig(): Promise<void> {
        return this._http.get<AdminAppConfig>(environment.connectivity.appConfig)
        .toPromise()
        .then((config: AdminAppConfig) => {
            this.config = config;
            this.config$.next(this.config);
        }).catch(err => console.error(err));
    }

  getConfig() {
    return this.config;
  }
}
