<div class="row">
    <div class="col s10 offset-s1 container small-page-fix">
        <div *ngIf="isLoading$ | async" class="table-overlay">
            <mat-spinner mode="indeterminate"></mat-spinner>
        </div>

        <div class="col s12">
            <p class="adm-title"> {{'pages.avatar-sessions.title' | translate}} </p>
        </div>

        <mat-icon vcldAutomationId="adm_app_avatar-session-overview-page_close_icon" class="dmt-close-icon" (click)="close()">close</mat-icon>

        <div class="col s12 tile-menu" *ngIf="!(noAvatarsAvailable$ | async) && !(isLoading$ | async)">
            <p class="text"> {{'pages.avatar-sessions-overview.acs-avatars' | translate }} </p>
            <vcld-adm-nav-tile *ngIf="showStoreAvatars$ | async" title="{{'pages.avatar-sessions-overview.acs-avatar-visufit' | translate }}" link="/accessed-avatars" styleForNoWraper="restricted-height" source="visufit"
                icon="perm_identity" additionalIcon="cloud_done">
            </vcld-adm-nav-tile>
            <vcld-adm-nav-tile *ngIf="showCameronAvatars$ | async" title="{{'pages.avatar-sessions-overview.acs-avatar-cameron' | translate }}" wrapperIcon="tablet-icon" link="/accessed-avatars" source="cameron" icon="perm_identity" additionalIcon="cloud_done">
            </vcld-adm-nav-tile>
        </div>

        <div class="col s12 tile-menu" *ngIf="!(noAvatarsAvailable$ | async) && !(isLoading$ | async)">
            <p class="text"> {{'pages.adm-overview.avatars' | translate }} </p>
            <vcld-adm-nav-tile *ngIf="showStoreAvatars$ | async" title="{{'pages.avatar-sessions-overview.unaccessed-avatar-visufit' | translate }}" link="/unaccessed-avatars" styleForNoWraper="restricted-height" source="visufit"
                icon="perm_identity" additionalIcon="cloud_upload">
            </vcld-adm-nav-tile>
            <vcld-adm-nav-tile *ngIf="showCameronAvatars$ | async" title="{{'pages.avatar-sessions-overview.unaccessed-avatar-cameron' | translate }}" wrapperIcon="tablet-icon" link="/unaccessed-avatars" source="cameron" icon="perm_identity" additionalIcon="cloud_upload">
            </vcld-adm-nav-tile>
        </div>

        <div class="col s12 tile-menu" *ngIf="(noAvatarsAvailable$ | async)">
            <p class="text"> {{'pages.avatar-sessions-overview.no-avatars' | translate }} </p>
        </div>
    </div>
</div>