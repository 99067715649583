<p mat-dialog-title class="centering bold"> Upload Demo Avatar </p>
<mat-dialog-content>
        <p class="centering"> Upload demo avatar for consumerId and storeId from customerNumber <strong>{{customerNumber}}</strong> </p>
        <mat-form-field appearance="outline">
            <mat-label>Consumer-ID</mat-label>
            <input #consumerIdInput matInput type="number" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Store-ID</mat-label>
            <input #storeIdInput matInput type="number" />
        </mat-form-field>
    <mat-checkbox [color]="'primary'" #cameronInput>Cameron-Avatar</mat-checkbox>
</mat-dialog-content>

<mat-dialog-actions [align]="'center'">
    <button mat-dialog-close mat-stroked-button color="primary"> {{'components.buttons.cancel' | translate}} </button>
    <button mat-flat-button color="primary"
        (click)="uploadAvatar(consumerIdInput.value, storeIdInput.value, cameronInput.checked)" [disabled]="loading">
        Add Demo Avatar </button>
</mat-dialog-actions>