import { Inject, Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-adm-delete-dialog',
    templateUrl: './avatar-session-delete.dialog.component.html',
    styleUrls: ['./avatar-session-delete.dialog.component.scss']
})
export class AvatarSessionDeleteDialogComponent {

    constructor(private dialogRef: MatDialogRef<AvatarSessionDeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) private data) { }

}
