
export class SettingDetailsSubmitFlagModel{
     public Flagtype:SettingsFlagType;
     public Flag:boolean;
}

export enum SettingsFlagType{
    ModifiedFlag,
    OpticianNameChanged,
    LogoUpdated,
    BannerImageUpdated,
    InvitationMailChecked,
    InvitationTypeChanged,
    InvitationLanguageChanged,
    InvitationMailContentChanged,
    NotificationLanguageChanged,   
    PriceFlagEnabled,
    ColorFlagEnabled,
    ModelNameFlagEnabled,
    MaterialFlagEnabled,
    SizeFlagEnabled,
}

