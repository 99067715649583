<div class="row">
    <!-- Close button -->
    <mat-icon vcldAutomationId="adm_app_avatar-session-overview-page_close_icon" class="dmt-close-icon" routerLink="/avatar-overview">close</mat-icon>

    <div class="col s10 offset-s1 small-page-fix" style="margin-top: 64px; position: relative;">
        <div *ngIf="isLoading" class="table-overlay">
            <mat-spinner mode="indeterminate"></mat-spinner>
        </div>

        <div class="col s12">
            <p class="adm-title">
                {{ "pages.avatar-sessions.title" | translate }}
            </p>
        </div>

        <div class="col s12">
            <p class="subheading" *ngIf="isCameronSource">
                {{ "pages.avatar-sessions-overview.unaccessed-avatar-cameron" | translate }}
            </p>

            <p class="subheading" *ngIf="!isCameronSource">
                {{ "pages.avatar-sessions-overview.unaccessed-avatar-visufit" | translate }}
            </p>
        </div>

        <div class="col s12 filter-export">
            <mat-form-field class="filter-drop" *ngIf="multipleStores$ | async">
                <mat-label>{{'pages.dmt-overview.selected-store' | translate}}</mat-label>
                <mat-select [value]="selectedStore$ | async" vcldAutomationId = "adm_app_vis-adm-unacs-session-page_select-store_ddl"(valueChange)="changeStore($event)">
                    <mat-option [value]="avSession.allStores">{{ "pages.avatar-sessions.all_stores" | translate }}</mat-option>
                    <mat-option *ngFor="let store of stores$ | async" [value]="store" vcldAutomationId = "adm_app_vis-adm-unacs-session-page_select-store_option">{{store.customName}}</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-flat-button color="primary" class="export-btn" vcldAutomationId = "adm_app_vis-adm-unacs-session-page_export_btn" download="Avatars unaccessed.csv" (click)="downloadUnacs()">
                {{ "pages.avatar-sessions.export" | translate}}: {{ "components.buttons.acsUnaccessed" | translate }}
            </button>
        </div>

        <!-- Email Table -->
        <table featureFlag="EmailResendEnabled" class="col s12" mat-table [dataSource]="dataSrc" matSort matSortDirection="asc">
            <!-- Definition for "Email" -->
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef style="width: 30%">
                    {{ "pages.avatar-sessions.mail" | translate }}
                </th>
                <td mat-cell *matCellDef="let row" style="width: 30%">
                    {{ row.email }}
                </td>
            </ng-container>

            <!-- Definition for "Id" -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 30%" vcldAutomationId="adm_app_vis-adm-unacs-session-page_avatar-id_column">
                    {{ "pages.avatar-sessions.avatar_id" | translate }}
                </th>
                <td mat-cell *matCellDef="let row" style="width: 30%">
                    {{ row.id }}
                </td>
            </ng-container>

            <!-- Definition for "UploadedAt" -->
            <ng-container matColumnDef="uploadedAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%" vcldAutomationId="adm_app_vis-adm-unacs-session-page_uploaded-on_column">
                    {{ "pages.avatar-sessions.uploaded_on" | translate }}
                </th>
                <td mat-cell *matCellDef="let row" style="width: 20%">
                    {{ row.uploadedAt | date }}
                </td>
            </ng-container>

            <!-- Action Definition -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width: 20%; text-align: center;">
                    {{ "pages.avatar-sessions.resend" | translate }}
                </th>
                <td td mat-cell *matCellDef="let row" style="width: 20%; text-align: center; position: relative;">
                    <div *ngIf="row.resendTriggered && !(row.resendDate)" class="multi-frame-element cell-overlay">
                        <mat-spinner [diameter]="40" mode="indeterminate"></mat-spinner>
                    </div>
                    
                    <button *ngIf="(!row.resendDate) && (resendAvailable(row.email))" mat-icon-button class="text1" vcldAutomationId = "adm_app_vis-adm-unacs-session-page_resend-invitation-mail_icon" [disabled]="row.resendTriggered"
                        matTooltip="{{'tooltips.avatar-sessions.resend' | translate}}"
                        (click)="resendInvitation(row.opticianId, row.id, row.email)" style="color: black !important;">
                        <mat-icon>mail_outline</mat-icon>
                    </button>

                    <mat-icon *ngIf="!resendAvailable(row.email)" mat-icon-button class="text1 platinum-text nosent" disabled="true"
                        matTooltip="{{'tooltips.avatar-sessions.invalidmail' | translate}}">
                        <mat-icon>unsubscribe</mat-icon>
                    </mat-icon>

                    <mat-icon *ngIf="row.resendDate" mat-icon-button class="text1 sentOut" vcldAutomationId = "adm_app_vis-adm-unacs-session-page_resent-invitation-mail_icon"
                        matTooltip="{{'tooltips.avatar-sessions.resent' | translate: {date: row.resendDate | date} }}">
                        mark_email_read</mat-icon>

                </td>
            </ng-container>

            <!-- DataSet itself -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <!-- SMS Table -->
        <table featureFlag="SMSResendEnabled" class="col s12" mat-table [dataSource]="dataSrc" matSort matSortDirection="asc">
            <!-- Definition for "Email" -->
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef style="width: 30%">
                    {{ "pages.avatar-sessions.phone" | translate }}
                </th>
                <td mat-cell *matCellDef="let row" style="width: 30%">
                    {{ row.phone || '-' }}
                </td>
            </ng-container>

            <!-- Definition for "Id" -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 30%">
                    {{ "pages.avatar-sessions.avatar_id" | translate }}
                </th>
                <td mat-cell *matCellDef="let row" style="width: 30%">
                    {{ row.id }}
                </td>
            </ng-container>

            <!-- Definition for "UploadedAt" -->
            <ng-container matColumnDef="uploadedAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%">
                    {{ "pages.avatar-sessions.uploaded_on" | translate }}
                </th>
                <td mat-cell *matCellDef="let row" style="width: 20%">
                    {{ row.uploadedAt | date }}
                </td>
            </ng-container>

            <!-- Action Definition -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width: 20%; text-align: center;">
                    {{ "pages.avatar-sessions.resend_sms" | translate }}
                </th>
                <td td mat-cell *matCellDef="let row" style="width: 20%; text-align: center; position: relative;">
                    <div *ngIf="row.resendTriggered && !(row.resendDate)" class="multi-frame-element cell-overlay">
                        <mat-spinner [diameter]="40" mode="indeterminate"></mat-spinner>
                    </div>
                    
                    <button *ngIf="(!row.resendDate)" mat-icon-button class="text1" [disabled]="row.resendTriggered"
                        matTooltip="{{'tooltips.avatar-sessions.resend_sms' | translate}}"
                        (click)="resendInvitationSMS(row.opticianId, row.id, row.phone)" style="color: black !important;">
                        <mat-icon>mail_outline</mat-icon>
                    </button>

                    <mat-icon *ngIf="row.resendDate" mat-icon-button class="text1 sentOut"
                        matTooltip="{{'tooltips.avatar-sessions.resent_sms' | translate: {date: row.resendDate | date} }}">
                        mark_email_read</mat-icon>

                </td>
            </ng-container>

            <!-- DataSet itself -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <!-- Paginator -->
        <mat-paginator class="col s12" [length]="dataSrcTotal" [pageSizeOptions]="[10, 15, 25, 50]"></mat-paginator>
    </div>
</div>