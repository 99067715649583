import { StoreSettingsEmailsModel } from "./store-setting.email.model";

export class StoreSettingsObjectModel {
    public storeSettingsEmailModel:StoreSettingsEmailsModel;
    public FileUploadObjectList:FileUploadObjectModel[];
}


export class FileUploadObjectModel{
    public uploadfile:File;
    public opticianId:string;
    public uploadFileType:UploadFileType;
}

export enum UploadFileType{
    LogoInput,
    BannerInput
}