import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { VISVendorsModule } from 'visvendors';
import { UtilitiesModule } from 'utilities';
import { VISEnvironmentModule } from 'visenvironment';
import { VISAutoAuthModule } from 'visauto-auth';

import { NavigationTileComponent } from './components/navigation-tile/navigation-tile.component';
import { LoadingSpinnerComponent } from './components/loadingSpinner/loading-spinner.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ECPLogoInputComponent } from '../app-admin/components/logo-input/logo-input.component';
import { CustomFileUploadComponent } from '../app-admin/components/custom-file-input/file-upload.component';
import { TranslateModule } from '@ngx-translate/core';
import { BannerInputComponent } from '../app-admin/components/banner-input/banner-input.component';
import {overflowingTooltipDirective} from "../directives/overflowingContent.directive"

@NgModule({
  declarations: [
    NavigationTileComponent,
    LoadingSpinnerComponent,
    ECPLogoInputComponent,
    BannerInputComponent,
    CustomFileUploadComponent,
    overflowingTooltipDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatFormFieldModule,
    FormsModule,
    TranslateModule.forChild({}),
    ReactiveFormsModule,
    VISVendorsModule,
    VISEnvironmentModule,
    VISAutoAuthModule,
    UtilitiesModule,
    MatTableModule,
  ],
  exports: [
    NavigationTileComponent,
    LoadingSpinnerComponent,
    ECPLogoInputComponent,
    BannerInputComponent,

    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,

    VISVendorsModule,
    VISEnvironmentModule,
    VISAutoAuthModule,
    UtilitiesModule,
    CustomFileUploadComponent,
    overflowingTooltipDirective
  ],
  providers: [],
})
export class AdminSharedModule { }