<div class="col s12 settings" *ngIf="!isLoading">
    <div class="col s12" *ngIf="isSingleOptician">
        <p class="title">
            <span class="bold">{{'pages.store-settings.settings' | translate}}: </span>
            <span class="leading-headline cyan-text">{{opticianCustomName}}</span>
        </p>
    </div>
    <!-- Store name -->
    <div class="col s12 divider">
        <p class="section-heading">{{'pages.store-settings.custom_name_title' | translate}}</p>
        <p class="quick-silver-text subtitle">{{'pages.store-settings.subtitles.custom_name_subtitle' | translate}}</p>
        <mat-form-field class="col s7 m7 l4" style="height: 61px;" appearance="outline">
            <mat-label>{{email.opticianName}}</mat-label>
            <input #customNameInput matInput type="text" vcldAutomationId="adm_app_vcld-adm-store-settings_cutomer-name-txt_input-field" [(ngModel)]="opticianCustomName"
                placeholder="{{'pages.store-settings.custom_name_placeholder' | translate}}" (change)="onOpticianNameChanged($event)" [disabled]="!canEdit"/>

        </mat-form-field>
        <button [disabled]="customNameInput.value == email.opticianName || customNameInput.value == '' || !canEdit" mat-flat-button color="primary" vcldAutomationId="adm_app_vcld-adm-store-settings_store-name-save_btn"
        style="margin-left: 24px; margin-top: 2px;" class="col s4 m2 l2" 

            (click)="saveEmail()">{{'components.buttons.save' | translate}}</button>
    </div>

    <!-- Store logo -->
    <div class="col s12 divider">
        <vcld-adm-logo-input [origin]="'store-settings'" [opticianId]="email.opticianId" [canEdit]="canEdit" (fileInputChange)="onLogoFileInputChange($event)"></vcld-adm-logo-input>

    </div>

    <!-- Banner Image -->
    <div class="col s12 divider">
        <p class="section-heading banner">
            {{ 'pages.store-settings.banner.title' | translate }}
            <mat-icon class="quick-silver-text info" matTooltipClass="banner-tooltip" matTooltip="{{ 'tooltips.banner.ratio' | translate: {aspectRatio: '16:9'} }} 
            {{ 'tooltips.banner.filesize' | translate: {fileSize: '4MB'} }} 
            {{ 'tooltips.banner.fileformat' | translate: {fileFormat: '.jpg, .jpeg, .png'} }}">info</mat-icon>
        </p>
        <p class="quick-silver-text banner"> {{ 'pages.store-settings.banner.subtitle' | translate }}</p>
        <!-- Custom / Default Banner -->
        <div class="col s12 banner-image">
            <mat-radio-group [(ngModel)]="customBannerEnabled" (change)="onBannerImageChanged()" class="col s12" 
                [disabled]="!canEdit">
                <mat-radio-button [value]="false" vcldAutomationId= "adm_app_vcld-adm-store-settings_default-banner-radio_btn"> {{ 'pages.store-settings.banner.default' | translate }}
                    <div class="image-container">
                        <div *ngIf="!(defaultBanner$ | async)" class="col s12 table-overlay">
                            <mat-spinner [diameter]="50" mode="indeterminate"></mat-spinner>
                        </div>
                        <img [src]="(defaultBanner$ | async)" class="defaultBanner">
                    </div>
                </mat-radio-button>
                <mat-radio-button [value]="true" vcldAutomationId= "adm_app_vcld-adm-store-settings_custom-banner-radio_btn"> {{ 'pages.store-settings.banner.custom' | translate }}
                    <div>
                    <vcld-banner-input (bannerDeleted)="fallbackBannerImage()" (bannerExists)="onBannerImageExists($event)" (bannerSaved)="onBannerImageSaved()" (fileInputChange)="onBannerFileInputChange($event)" [opticianId]="email.opticianId" [canEdit]="canEdit" [index]="index"></vcld-banner-input>
                </div>
                </mat-radio-button>
            </mat-radio-group>
            <button mat-flat-button color="primary" vcldAutomationId="adm_app_vcld-adm-store-settings_banner-save_btn" [disabled]="!canEdit || !(savableBannerOptions$ | async)" mat-button 
                class="col s4 m2 l2 offset-s8 offset-m10" (click)="saveEmail()">{{'components.buttons.save' |
                translate}}
            </button>
        </div>
    </div>
    <!-- Invitation mail -->
    <div class="col s12 divider" featureFlag="EmailInvitationEnabled">
        <p class="section-heading">{{'pages.store-settings.invitation_title' | translate}}</p>
        <p class="quick-silver-text subtitle">{{'pages.store-settings.subtitles.invitation_subtitle' | translate}}</p>
        <div style="height: 24px;">
            <mat-checkbox [color]="'primary'" [disabled]="!canEdit" class="col s12" vcldAutomationId="adm_app_vcld-adm-store-settings_invitation-mail_cBox" appearance="outline" [checked]="email.invitationEnabled"
                (change)="onCheckboxChanged($event)">
                {{'pages.store-settings.invitation' | translate}}
            </mat-checkbox>
        </div>

        <div class="invitation-container">
            <!-- Left-Side -->
            <div class="invitation-settings">

                <!-- Language Drop-Down -->
                <div class="col s12">
                    <p class="quick-silver-text heading"> {{'pages.store-settings.invitation_language' | translate}}
                    </p>
                    <mat-form-field class="col s12 language-settings">
                        <mat-select (selectionChange)="languageChange()" vcldAutomationId= "adm_app_vcld-adm-store-settings_invitation-language_ddl" [(value)]="_selectedLanguage"
                            [disabled]="!email?.invitationEnabled || !canEdit">
                            <mat-option *ngFor="let lang of availableLanguages" [value]="lang.code">
                                {{lang.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Custom / Default Invitation Options -->
                <div class="col s12">
                    <p class="quick-silver-text heading">{{'pages.store-settings.custom.invitation_type' | translate}}
                    </p>
                    <mat-radio-group [(ngModel)]="defaultInvitation" (change)="onInvitationTypeChanged($event)"
                        class="col s12 invitation-option" [disabled]="!email?.invitationEnabled || !canEdit">
                        <mat-radio-button [value]="true" vcldAutomationId= "adm_app_vcld-adm-store-settings_default-invitation-radio_btn"> {{'pages.store-settings.custom.default' | translate}}
                        </mat-radio-button>
                        <mat-radio-button [value]="false" vcldAutomationId= "adm_app_vcld-adm-store-settings_custom-invitation-radio_btn"> {{'pages.store-settings.custom.custom' | translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <!-- Variables explanation -->
                <div class="col s12" *ngIf="!defaultInvitation && defaultInvitation!=undefined">
                    <p class="quick-silver-text heading"> {{'pages.store-settings.custom.text_modules' | translate}}
                        <mat-icon class="var-icon" vcldAutomationId="adm_app_vcld-adm-store-settings_info-text-modules_icon" matTooltipPosition="after" matTooltipClass="banner-tooltip"
                            matTooltip="{{'tooltips.store-settings.text_modules' | translate}}">
                            info</mat-icon>
                    </p>

                    <table [ngClass]="{'quick-silver-text': !(email?.invitationEnabled)}">
                        <tbody>
                            <tr>
                                <td class="bold">%optician%</td>
                                <td> {{opticianCustomName}} </td>
                            </tr>
                            <tr>
                                <td class="bold">%emailaddress%</td>
                                <td> {{'pages.store-settings.custom.customer_mail' | translate}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <!-- Right-Side -->
            <div class="invitation-edit">
                <p class="quick-silver-text heading" *ngIf="!defaultInvitation && defaultInvitation!=undefined">
                    {{'pages.store-settings.custom.invitataion_text' | translate}}</p>
                <div class="col s12" *ngIf="!defaultInvitation && defaultInvitation!=undefined">
                    <div *ngIf="templateLoading" class="col s12 table-overlay"
                        style="margin-left: 16px; margin-top: 16px; margin-bottom: 242px;">
                        <mat-spinner [diameter]="50" mode="indeterminate"></mat-spinner>
                    </div>
                    <vcld-adm-invitation-mail-component-host *ngFor="let component of (invitationTemplate$ | async)?.components; let i = index"
                        [component]="component" [componentList]="invitationDatabaseComponents$" [canEdit]="email?.invitationEnabled && canEdit" [index]="i" (valueChanged)="onValuechanged($event)" class="settings-component">

                    </vcld-adm-invitation-mail-component-host>
                </div>

                <div class="col s12 buttons">
                    <button mat-stroked-button *ngIf="!defaultInvitation" color="primary" vcldAutomationId="adm_app_vcld-adm-store-settings_invitation-preview_btn" (click)="openTemplateDialog()" [disabled]="templateLoading">
                    {{'components.buttons.preview' | translate}}
                    </button>
                    <button mat-flat-button mat-button 
                     color="primary" vcldAutomationId="adm_app_vcld-adm-store-settings_invitation-save_btn" [disabled]="(isInvitataionMailSubmitDisabled && !isInvitationLanguageChanged) || templateLoading" (click)="saveEmail()">{{'components.buttons.save' | translate}}
                    </button>
                </div>
            </div>
        </div>

    </div>

    <div class="col s12 divider" featureFlag="SMSInvitationEnabled">     
        <p  class="section-heading margin-bottom-12">{{'pages.store-settings.sms_invitation_title' | translate}}</p>      
        <div style="height: 24px;">
            <mat-checkbox [color]="'primary'" [disabled]="!canEdit" class="col s12" appearance="outline" [checked]="email.invitationEnabled"
                (change)="onCheckboxChanged($event)">
                {{'pages.store-settings.sms_invitation' | translate}}
            </mat-checkbox>
        </div>
    </div>

    <!-- Mail addresses -->
    <div class="col s12 divider">
        <p vcldautomationid="adm_app_vcld-adm-store-settings_notification-mails_header" class="section-heading">{{'pages.store-settings.notification_title' | translate}}</p>      
        <p class="quick-silver-text subtitle">{{'pages.store-settings.subtitles.notification_subtitle' | translate}}</p>
        <div class="col s12">
            <p class="quick-silver-text heading"> {{'pages.store-settings.notification_language' | translate}} </p>
            <mat-form-field class="col s7 m7 l4">
                <mat-select [(value)]="_selectednotificationLanguage" vcldAutomationId= "adm_app_vcld-adm-store-settings_notification-language_ddl" (selectionChange)="notificationLanguageChange($event)"  [disabled]="!canEdit">
                    <mat-option *ngFor="let lang of availableLanguages" [value]="lang.code">
                        {{lang.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button [disabled]="!isNotificationLanguageChanged || !canEdit" mat-flat-button color="primary" vcldAutomationId="adm_app_vcld-adm-store-settings_save-notification-language_btn"
            style="margin-left: 24px; margin-top: 2px;" class="col s4 m2 l2" 
                (click)="saveEmail()">{{'components.buttons.save' | translate}}</button>
        </div>
    </div>

    <div class="col s12 divider">
        <p vcldautomationid="adm_app_vcld-adm-store-settings_mail-address-for-receiving-notification_header" class="section-heading">{{'pages.store-settings.mail' | translate }}</p>
        <p class="quick-silver-text subtitle">{{'pages.store-settings.subtitles.mail_subtitle' | translate}}</p>
        <div class="col s12" *ngFor="let address of _internalEmails; let i = index;">
            <vcld-adm-email-input [mailAddress]="address" [index]="i" [showRemove]="!(onlyMail$ | async)"
                (onRemove)="removeEmail($event)" (onChange)="internalEmailValueChange($event)" (onSave)="saveEmail($event)" [disable]="!canEdit">
            </vcld-adm-email-input>
        </div>
        <div class="col s7 m7 l6">
            <button class="col s12 m12 l6" mat-stroked-button vcldAutomationId="adm_app_vcld-adm-store-settings_add-mail_btn" (click)="addEmail()" [disabled]="!canEdit" [color]="'primary'">
                <mat-icon>add</mat-icon> {{'pages.store-settings.add_mail' | translate}}
            </button>
        </div>
    </div>

    <!-- Frame Information -->
    <div class="col s12 ">
        <p class="section-heading"> {{'pages.store-settings.frame_information' |
            translate}} </p>
            <p class="quick-silver-text subtitle">{{'pages.store-settings.subtitles.frame_information_subtitle' | translate}}</p>

        <!-- Model name -->
        <div class="col s12">
            <vcld-adm-model-name-input [enabled]="email?.featureFlags?.modelNameEnabled"
                (onChange)="onModelNameChanged($event)" [disabled]="!canEdit">
            </vcld-adm-model-name-input>
        </div>

        <!-- Color -->
        <div class="col s12">
            <vcld-adm-color-input [enabled]="email?.featureFlags?.colorEnabled" (onChange)="onColorChanged($event)"
                [disabled]="!canEdit">
            </vcld-adm-color-input>
        </div>

        <!-- Size -->
        <div class="col s12">
            <vcld-adm-size-input [enabled]="email?.featureFlags?.sizeEnabled" (onChange)="onSizeChanged($event)"
                [disabled]="!canEdit">
            </vcld-adm-size-input>
        </div>


        <!-- Material -->
        <div class="col s12">
            <vcld-adm-material-input [enabled]="email?.featureFlags?.materialEnabled"
                (onChange)="onMaterialChanged($event)" [disabled]="!canEdit">
            </vcld-adm-material-input>
        </div>

        <!-- Pricing -->
        <div class="col s12">
            <vcld-adm-pricing-input [enabled]="email?.featureFlags?.pricingEnabled"
                (onChange)="onPricingChanged($event)" [disabled]="!canEdit">
            </vcld-adm-pricing-input>
        </div>
        <div class="col s4 m2  offset-s8 offset-m10">
        <button [disabled]="!canEdit || !isFeatureFlagChecked" mat-flat-button 
        color="primary" vcldAutomationId="adm_app_vcld-adm-store-settings_frame-info-save_btn" (click)="saveEmail()">{{'components.buttons.save' | translate}}
       </button>
    </div>
    </div> 
</div>
