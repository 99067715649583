import { Injectable } from "@angular/core";
import { AdminAppConfig } from "../types/appConfig";
import { AppConfigService } from "./appConfig.service";

@Injectable({ providedIn: "root" })
export class FeatureFlagsService {

    private featureFlags: AdminAppConfig;

    constructor(private _config: AppConfigService) {
      this.featureFlags = this._config.getConfig();
    }

    isFeatureFlagEnabled(flag: string) {
      return this.featureFlags && this.featureFlags[flag];
    }
  }