import { Inject, Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AdminAppEnvironment as environment } from 'visenvironment';


@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-about-dialog',
    templateUrl: 'about.dialog.component.html',
    styleUrls: ['about.dialog.component.scss']
})
export class AboutDialogComponent {


    constructor(
        private dialogRef: MatDialogRef<AboutDialogComponent>,
        private translate: TranslateService
    ) { }

    public get version() {
        return environment.version.split('+').shift();
    }

    public get materialNumber() {
        return environment.material_number;
    }
}
