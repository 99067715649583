/* eslint-disable @angular-eslint/component-selector */
import { Component, ErrorHandler, OnInit } from '@angular/core';
import { SinglePermission, VisucloudRoleService, RoleAssignment } from 'visauto-auth';
import { ComponentCanDeactivate } from 'utilities';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OAuthService } from 'angular-oauth2-oidc';
import { SecurityService } from '../../services/security.service';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from '../../components/snackbar/snackbar.component';

@Component({
    selector: 'vis-adm-role-management-page',
    templateUrl: './role-management.page.component.html',
    styleUrls: ['./role-management.page.component.scss']
})
export class RoleManagementPageComponent implements ComponentCanDeactivate, OnInit {

    constructor(
        public readonly security: VisucloudRoleService,
        private readonly translate: TranslateService,
        private readonly sec: SecurityService,
        private readonly snack: MatSnackBar,
        private readonly oAuth: OAuthService,
        private errorHandler: ErrorHandler
        ) { }

    public isLoading = false;

    public displayedColumns: string[] = [
        'checkbox',
        'displayName',
        'description',
        'key',
    ];

    ngOnInit() {

        if (this.oAuth.getIdToken()) {
            this.security.fetchPermissionSet();
            this.security.fetchAvailableRoles();
        }
    }

    public getTranslation(key: string) {
        return `application.roles.${key}`;
    }


    onSave() {
        this.isLoading = true;

        this.security.savePermissionsSet().toPromise().then(result => {
            this.isLoading = false;
            this.snack.openFromComponent(SnackbarComponent, {
                data: {
                    icon: 'check',
                    heading: "errors.success",
                    message: "pages.role-management.success",
                    btnIcon: 'close',
                    support: false
                },
                panelClass: 'error-panel',
                verticalPosition: 'top',
                horizontalPosition: 'center',
                duration: 2000
            });
            this.sec.resolvePermissionsByRole(false);
        }).catch(err => {
            this.errorHandler.handleError(err);
        });
    }

    onChangeAvailableRolePermissionsAssignment(role: RoleAssignment, permission: SinglePermission) {
        if (this.security.checkAvailableRolePermission(role, permission.key)) {
            this.security.removePermission(role, permission);
        } else {
            this.security.addPermissions(role, permission);
        }
    }

    canDeactivate(): boolean | Observable<boolean> {
        return this.security.hasChanges() ? false : true;
    }

    resetDeactivate(): void {
        this.security.resetChanges();
    }
}
