<div mat-dialog-title class="dialog-header icon-centering">
  <mat-icon class="icon">warning</mat-icon>
  <p>{{'components.dialogs.avatar-session-delete.avatar_deletion' | translate}}</p>
</div>

<mat-dialog-content>
  <p class="centering">{{'components.dialogs.avatar-session-delete.confirmation' | translate}}</p>
</mat-dialog-content>

<mat-dialog-actions [align]="'center'">
  <button mat-dialog-close mat-stroked-button color="primary">{{'components.buttons.cancel' |
    translate}}</button>
  <button mat-flat-button [mat-dialog-close]="true" color="primary">{{'components.buttons.delete' |
    translate}}</button>
</mat-dialog-actions>