import { shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpClient } from '@angular/common/http';
import { AvatarCreationSession, AvatarsCount } from '../typings/avatar-creation-session';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { AdminAppEnvironment } from 'visenvironment';

export interface Store {

    /**
     * Store ID of the selected store in ZeissID
     */
    opticianId: string;

    /**
     * Selected Optician name from ECP-StoreSettings
     */
    customName: string;
}

@Injectable({ providedIn: 'root' })
export class AvatarSessionService {

    public stores$: ReplaySubject<Store[]> = new ReplaySubject(1);

    public allStores: Store = {opticianId: 'all', customName: 'All Stores'};

    public selectedStore$: BehaviorSubject<Store> = new BehaviorSubject(this.allStores);

    public avatarsBySource$: Observable<AvatarsCount> = this.getAllSessionsCount();

    constructor(private oAuth: OAuthService, private http: HttpClient) {
        this.stores$.next([]);
    }

    public resetStoreSelection() {
        this.selectedStore$.next(this.allStores);
    }

    public async fetchStores(): Promise<void> {
        const stores = await this.http.get<Store[]>(AdminAppEnvironment.connectivity.getStores, {headers: {Authorization: `Bearer ${this.oAuth.getIdToken()}`}}).toPromise();
        
        this.stores$.next(stores);
    }

    public getAllSessionsCount(opticianId: string = 'all'): Observable<AvatarsCount> {
        let uri = AdminAppEnvironment.connectivity.avatarsCountEndpoint.replace('{opticianId}', opticianId);
        return this.http.get<AvatarsCount>(uri, { headers: { Authorization: 'Bearer ' + this.oAuth.getIdToken() } });
    }

    public getAvatarSessions(
        pageSize: number,
        pageIndex: number,
        sortField: string,
        sortDirection: string,
        accessed: boolean,
        source?: string
    ): Observable<{ total: number, items: AvatarCreationSession[] }> {

        let uri = AdminAppEnvironment.connectivity.avatarSessionEndpoint.replace('{opticianId}', this.selectedStore$.getValue().opticianId);
        uri += '?pageSize=' + pageSize.toString() + '&page=' + pageIndex.toString() + '&sort=' + 'uploadedAt' + '&order=' + sortDirection + '&accessed=' + accessed;

        if (source) uri = uri + '&source=' + source;

        return this.http.get<{ total: number, items: AvatarCreationSession[] }>
            (uri, { headers: { Authorization: 'Bearer ' + this.oAuth.getIdToken() } });
    }

    public deleteAvatarSession(sessionId: string) {
        const uri = AdminAppEnvironment.connectivity.deleteAvatarSessionEndpoint + '/' + sessionId;
        return this.http.delete(uri, { headers: { Authorization: `Bearer ${this.oAuth.getIdToken()}` } });
    }

    public downloadACSFile(accessed: boolean, source: string): Observable<Blob> {
        const uri = AdminAppEnvironment.connectivity.avatarSessionEndpoint.replace('{opticianId}', this.selectedStore$.getValue().opticianId) + '/download?accessed=' + accessed + "&source=" + source;

        return this.http.get(uri, { headers: { Authorization: `Bearer ${this.oAuth.getIdToken()}` }, responseType: 'blob' });
    }

}
