import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ApplicationInsightsService } from '../../services/applicationInsights.Service';
import { AdminAppEnvironment as Environment } from 'visenvironment';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OAuthService } from 'angular-oauth2-oidc';
import { SnackbarComponent } from '../../components/snackbar/snackbar.component';


@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-adm-onboarding-wizard-page',
    templateUrl: './onboarding-wizard.page.component.html',
    styleUrls: ['./onboarding-wizard.page.component.scss']
})
export class OnboardingWizardPageComponent {

    constructor(
        private readonly translate: TranslateService,
        private readonly appInsights: ApplicationInsightsService,
        private readonly http: HttpClient,
        private readonly snack: MatSnackBar,
        private readonly oAuth: OAuthService
    ) { }

    public opticianCustomerNumber: string = null;
    public opticianId: string = null;

    public changeOpticianId(opticianId: string) {
        this.opticianId = opticianId;
    }

    public changeCustomerNumber(customerNumber: string) {
        this.opticianCustomerNumber = customerNumber;
    }

    public initiateOnboarding(opticianId: string, customerNo?: string) {
        let subscriber = this.http.post(Environment.connectivity.ecp_onboarding,
            {
                opticianCustomerNumber: this.opticianCustomerNumber,
                opticianId: this.opticianId
            },
            {
                headers: {
                    Authorization: `Bearer ${this.oAuth.getIdToken()}`
                }
            }
        ).pipe(
            finalize(() => {
                subscriber.unsubscribe()
            })
        ).subscribe(
            (result: any) => {
                this.opticianCustomerNumber = null;
                this.opticianId = null;
                this.snack.openFromComponent(SnackbarComponent, {
                    data: {
                        icon: 'check',
                        heading: "errors.success",
                        message: "tooltips.ecp_onboarding.success",
                        btnIcon: 'close',
                        support: false
                    },
                    panelClass: 'error-panel',
                    verticalPosition: 'top',
                    horizontalPosition: 'center',
                    duration: 2000
                });
            },
            (_e) => {
                if (_e.status == 400) {
                    this.snack.openFromComponent(SnackbarComponent, {
                        data: {
                            icon: 'error',
                            heading: "errors.information",
                            message: "tooltips.ecp_onboarding.optician_id",
                            btnIcon: 'close',
                            support: false
                        },
                        panelClass: 'error-panel',
                        verticalPosition: 'top',
                        horizontalPosition: 'center'
                    });
                } else if (_e.status == 409) {
                    this.snack.openFromComponent(SnackbarComponent, {
                        data: {
                            icon: 'error',
                            heading: "errors.information",
                            message: "tooltips.ecp_onboarding.existing_customer",
                            btnIcon: 'close',
                            support: false
                        },
                        panelClass: 'error-panel',
                        verticalPosition: 'top',
                        horizontalPosition: 'center'
                    });
                } else {
                    this.snack.openFromComponent(SnackbarComponent, {
                        data: {
                            icon: 'info',
                            heading: "errors.sorry",
                            message: "tooltips.ecp_onboarding.error",
                            btnIcon: 'close',
                            support: false
                        },
                        panelClass: 'error-panel',
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        duration: 2000
                    });
                }
            },
        );
    }

}
