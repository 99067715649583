<div class="col s4 m4 l3 xl2 minwidth" (click)="navigate()" [style.opacity]="disabled ? '0.4' : '1'"
    matTooltip="{{ 'tooltips.dmt.start_campaign' | translate }}" [matTooltipDisabled]="!disabled || disableTooltip">
    <div class="col s11 platinum tile" [ngClass]="disabled ? '' : 'clickable'">
        <!-- Icon wrapper -->
        <div class="col s12 icons" [class]="styleForNoWraper" *ngIf="!wrapperIcon else wrapperImageIcon">
            <mat-icon>{{icon}}</mat-icon>
            <mat-icon [ngClass]="twoIcons ? 'dualIcon': 'noIcon' ">{{additionalIcon}}</mat-icon>
        </div>


        <ng-template #wrapperImageIcon>
            <div class="col s12 icons icon-wapper">
                <img src="assets/icons/tablet.svg" alt="">
                <div>
                    <mat-icon >{{icon}}</mat-icon>
                    <mat-icon [ngClass]="twoIcons ? 'dualIcon': 'noIcon' ">{{additionalIcon}}</mat-icon>    
                </div>
             </div>
        </ng-template>

        <!-- Title wrapper  -->
        <div class="col s12">
            <p class="title" id= "{{'adm_app_vcld-adm-nav-tile_'+title+'_btn'}}" [ngClass]="{'wrapper-margin': wrapperIcon}">{{title}}</p>
        </div>
    </div>
</div>