import { OAuthService } from 'angular-oauth2-oidc';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminAppEnvironment as environment } from 'visenvironment';

@Injectable()
export class LocationHttpInterceptor implements HttpInterceptor {
    constructor(private _oAuth: OAuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        // add auth header with jwt if account is logged in and request is to the api url
        if (this._oAuth.hasValidIdToken()) {
            const location = environment.location;
            if (location) {
                request = request.clone({
                    setHeaders: { 'x-location': location }
                });
            }    
        }

        return next.handle(request);
    }
}
