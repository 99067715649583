<div class="row">
    <div class="col s12">
     <p class="section-heading margin-bottom-0"> {{'pages.store-settings.store_logo_title' | translate}} </p>
     <p *ngIf="origin=='store-settings'"  class="quick-silver-text subtitle">{{'pages.store-settings.subtitles.logo_subtitle' | translate}}</p>
     <p *ngIf="origin=='dmt-settings'" class="quick-silver-text subtitle">{{'pages.store-settings.subtitles.logo_campaign_mailing_subtitle' | translate}}</p>
        <div class="col s12">
            <p>{{'pages.store-settings.current_logo' | translate}}</p>
            <div class="col s7 m7 l4 logo-container">
                <ng-container *ngIf="(ecpLogo$ | async); then image; else text"></ng-container>
                <ng-template #image>
                    <img [src]="(ecpLogo$ | async)" class="ecp-logo" />
                </ng-template>
                <ng-template #text>
                    <p class="error-text">{{'pages.store-settings.no_logo' | translate}}</p>
                </ng-template>
                <div *ngIf="loading" class="col s12 table-overlay">
                    <mat-spinner [diameter]="50" mode="indeterminate"></mat-spinner>
                </div>
            </div>
    
            <button [disabled]="((ecpLogo$ | async) == null) || !canEdit" mat-icon-button color="primary" vcldAutomationId="adm_app_vcld-adm-logo-input_delete-banner_btn" matTooltip="{{'components.buttons.delete' | translate}}" (click)="removeLogo($event)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    
        <div class="col s12 margin-top-10">
            <p class="file-input-description">{{'pages.store-settings.set_logo' | translate}}</p>
            <div class="col col s7 m7 l4 input-container">
                <custom-file-upload [origin]="'logo-Input'"  (inputFileChange)="fileInputChanged($event)" accept=".png, .jpg, .jpeg" [disabled]="!canEdit" [fileUpdated]="logoUpdated"></custom-file-upload>   
            </div>
            <button [disabled]="isDisabled || !canEdit"  matTooltip="{{'components.buttons.save' | translate}}"  mat-mini-fab color="primary" vcldAutomationId="adm_app_vcld-adm-logo-input_upload-store-logo_btn" (click)="saveLogo($event)">
                <mat-icon>upload</mat-icon>
            </button>
        </div>
    </div> 
</div>
