<div class="row">
    <div class="col s10 offset-s1 small-page-fix" style="margin-top: 64px">
        <div class="col s12">
            <p class="adm-title">
                {{'pages.centration_overview.title' | translate}}
            </p>
        </div>
        <ng-container *ngIf="dataSrc | async; else loading">
            <div class="col s12" style="margin-top: 48px">
                <p style="
                        margin-top: 8px;
                        line-height: 22px;
                        font-size: 16px;
                        font-weight: bold;
                    ">
                    {{'pages.centration_overview.overview' | translate}}
                </p>
            </div>

            <table class="col s12" mat-table [dataSource]="dataSrc" matSort matSortDirection="asc">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'pages.centration_overview.table.job_id' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.id }}</td>
                </ng-container>

                <ng-container matColumnDef="sessionId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'pages.centration_overview.table.avatar_id' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.sessionId }}</td>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'pages.centration_overview.table.created_at' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" style="min-width: 100px;">
                        {{ row.createdAt | date }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="source">
                    <th mat-header-cell *matHeaderCellDef>{{'pages.centration_overview.table.source' | translate}}</th>
                    <td mat-cell *matCellDef="let row">
                        {{ row.source | jobSource }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="jobType">
                    <th mat-header-cell *matHeaderCellDef>{{'pages.centration_overview.table.type' | translate}}</th>
                    <td mat-cell *matCellDef="let row" style="min-width: 50px;">
                        <mat-icon
                            matTooltip="{{(row.jobType === 0 ? 'tooltips.centration_overview.type_auto' : 'tooltips.centration_overview.type_manual') | translate}}"
                            matTooltipPosition="above">
                            {{ row.jobType | jobType}}
                        </mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{'pages.centration_overview.table.status' | translate}}</th>
                    <td mat-cell *matCellDef="let row" style="min-width: 50px;">
                        <mat-icon [ngClass]="getStatusCSS(row.status)" matTooltip="{{getStatusTooltip(row.status) | translate }}"
                            matTooltipPosition="above">
                            {{ row.status | jobStatus }}
                        </mat-icon>
                    </td>
                </ng-container>

                <!-- Action Definition -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">
                        {{'pages.centration_overview.table.action' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" style="width: 10%">
                        <button (click)="startReview(row.id)" pk="admin_view_centration" mat-icon-button
                            aria-label="start" class="text1">
                            <mat-icon>play_arrow</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- DataSet itself -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <!-- Paginator -->
            <mat-paginator class="col s12" [length]="dataSrcTotal" [pageSizeOptions]="[10, 15, 25, 50]"></mat-paginator>
        </ng-container>
        <ng-template #loading>
            <div class="loading">
                <mat-spinner mode="indeterminate"></mat-spinner>
            </div>
        </ng-template>
    </div>
</div>