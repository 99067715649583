import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AdminAppEnvironment } from 'visenvironment';

@Injectable({ providedIn: 'root' })
export class EmailService {

    constructor(
        private http: HttpClient,
        private oAuth: OAuthService,
    ){}

    public async resendInvitation(opticianId: string, sessionId: string) {
        const uri = AdminAppEnvironment.connectivity.resendInvitationEndpoint;
        return this.http.post(uri, {opticianId , sessionId}, { headers: { Authorization: `Bearer ${this.oAuth.getIdToken()}` }}).toPromise();
    }
}